import React, { Component } from "react";
import mapSvg from "../../../assets/images/WineMapRegions/map.svg";

import classes from "./WineMap.module.scss";

const mapItems = [
  {
    name: "AllWorld",
    tag: "mundo",
  },
  {
    name: "Acores",
    tag: "acores",
  },
  {
    name: "Alentejo",
    tag: "alentejo",
  },
  {
    name: "Algarve",
    tag: "algarve",
  },
  {
    name: "Beiras",
    tag: "beiras",
  },
  {
    name: "Douro",
    tag: "transmontano_douro",
  },
  {
    name: "Lisboa",
    tag: "lisboa",
  },
  {
    name: "Madeira",
    tag: "madeira",
  },
  {
    name: "Minho",
    tag: "minho",
  },
  {
    name: "Setubal",
    tag: "setubal",
  },
  {
    name: "Tejo",
    tag: "tejo",
  },
];

class WineMap extends Component {
  state = {
    activeRegion: null,
  };

  render() {
    const appLayout = localStorage.getItem("app_layout") || "ementas_digitais";
    const appLayoutConfig = require("../../../assets/layout-config.json");
    const currentLayoutConfig = appLayoutConfig[appLayout];

    const lang = localStorage.getItem("lang") || "pt";
    const translations = require(`../../../assets/translations/${lang}.json`);

    return (
      <div className={classes[appLayout]}>
        <button
          className={classes.BackButton}
          onClick={() => {
            this.setState({
              activeRegion: null,
            });

            this.props.close();
          }}
        >
          <span>
            {currentLayoutConfig.backButtonArrow ? (
              <span className={classes.arrow}>{"\uf177"}</span>
            ) : null}
            <span className={classes.title}>
              {translations.menu.buttons.back}
            </span>
          </span>
        </button>
        {currentLayoutConfig.regionalMap ? (
          <div className={classes.WineMap}>
            <span className={classes.origin}>
              {translations.menu.wine_regions.select_origin}
            </span>
            <span className={classes.hint}>
              {this.state.activeRegion
                ? translations.menu.wine_regions[this.state.activeRegion.tag]
                : translations.menu.wine_regions.click_hint}
            </span>
            <img className={classes.map} src={mapSvg} alt="map" />
            {this.state.activeRegion ? (
              <img
                className={classes.selectedRegion}
                src={require(`../../../assets/images/WineMapRegions/${this.state.activeRegion.name}.svg`)}
                alt="region"
              />
            ) : null}
            {mapItems.map((item) => (
              <button
                key={item.tag}
                className={
                  this.state.activeRegion &&
                  item.name !== this.state.activeRegion.name
                    ? classes[item.name]
                    : [classes[item.name], classes.selected].join(" ")
                }
                onClick={() => {
                  this.setState({
                    activeRegion: item,
                  });
                  return this.props.selected(item);
                }}
              />
            ))}
          </div>
        ) : (
          <div className={classes.WineMap}>
            <img
              className={classes.map}
              src={require("../../../assets/images/map.png")}
              alt="map"
            />
            {mapItems.map((item) => (
              <button
                key={item.tag}
                className={
                  item.name !== this.state.activeRegion
                    ? classes[item.name]
                    : [classes[item.name], classes.selected].join(" ")
                }
                onClick={() => {
                  this.setState({
                    activeRegion: item.name,
                  });
                  return this.props.selected(item);
                }}
              />
            ))}
          </div>
        )}
        <button
          className={[
            classes.AllRegionsButton,
            !this.state.activeRegion ? classes.selected : null,
          ].join(" ")}
          onClick={() => {
            this.setState({
              activeRegion: null,
            });

            return this.props.selected({
              name: "All Regions",
              tag: "all_regions",
            });
          }}
        >
          <span className={classes.title}>
            {translations.menu.wine_regions.all_regions}
          </span>
        </button>
      </div>
    );
  }
}

export default WineMap;
