import React, { Component } from "react";
import axios from "../../axios-menuscreen";

import classes from "./LandingPage.module.scss";

class LandingPage extends Component {
  state = {
    isShowingLandingPage: false,
  };

  loadMenuInfo() {
    const baseURL = "https://plataforma.ementasdigitais.pt/uploads/images/";

    axios
      .get()
      .then((response) => {
        this.setState({
          image: baseURL + response.data.image,
          languages: response.data.languages,
        });

        localStorage.setItem(
          "image",
          response.data.image ? baseURL + response.data.image : undefined
        );
        localStorage.setItem(
          "welcomeText",
          response.data.welcome ? response.data.welcomeText : ""
        );
        localStorage.setItem(
          "languages",
          JSON.stringify(response.data.languages)
        );
        localStorage.setItem(
          "sections",
          JSON.stringify(response.data.sections)
        );
        localStorage.setItem(
          "display_cart",
          response.data.parameters && response.data.parameters.display_cart
        );
        localStorage.setItem(
          "display_lang_side_sections",
          response.data.parameters &&
            response.data.parameters.display_lang_side_sections
        );
      })
      .catch((error) => {
        // Code 602 => Suspended
        if (
          error.response &&
          error.response.data &&
          error.response.data.code &&
          error.response.data.code === 602
        ) {
          localStorage.clear();
          window.open(window.location + "suspended", "_self", "");
        } else {
          console.warn("Serving from Cache");
          this.setState({
            image: localStorage.getItem("image"),
            welcomeText: localStorage.getItem("welcomeText"),
            welcomeBanner: localStorage.getItem("welcomeBanner"),
            languages: JSON.parse(localStorage.getItem("languages")),
            sections: JSON.parse(localStorage.getItem("sections")),
          });
        }
      });
  }

  setLanguage(lang) {
    this.setState({
      isShowingLandingPage: false,
    });
    localStorage.setItem("lang", lang);
    this.loadMenuInfo();
    window.location.reload();
  }

  componentDidMount() {
    if (!localStorage.getItem("lang")) {
      this.setState({
        isShowingLandingPage: true,
      });
    }

    this.loadMenuInfo();
  }

  render() {
    const appLayout = localStorage.getItem("app_layout") || "ementas_digitais";
    const appLayoutConfig = require("../../assets/layout-config.json");
    const currentLayoutConfig = appLayoutConfig[appLayout];
    const languages = this.state.languages || [];
    let landingPageTranslatedContent = {};
    languages.forEach((lang) => {
      landingPageTranslatedContent[lang] =
        require(`../../assets/translations/${lang}.json`).menu.landing_page;
    });

    return currentLayoutConfig.landingPage &&
      this.state.isShowingLandingPage ? (
      <div className={classes[appLayout]}>
        <div className={classes.LandingPage}>
          <div className={classes.logo}>
            <img src={this.state.image} alt="logo" />
          </div>
          <div className={classes.buttons}>
            {languages.map((lang) => (
              <button key={lang} onClick={() => this.setLanguage(lang)}>
                <span className={classes.title}>
                  {landingPageTranslatedContent[lang].button}
                </span>
              </button>
            ))}
          </div>
          <div className={classes.info}>
            {languages.map((lang) => (
              <span key={lang}>{landingPageTranslatedContent[lang].info}</span>
            ))}
          </div>
          <div className={classes.enjoy}>
            {languages.map((lang) => (
              <span key={lang}>{landingPageTranslatedContent[lang].enjoy}</span>
            ))}
          </div>
        </div>
      </div>
    ) : null;
  }
}

export default LandingPage;
