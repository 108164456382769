import React, { Component } from "react";

import WineMap from "./WineMap/WineMap";
import WineItem from "./WineItem/WineItem";

import classes from "./WineItems.module.scss";

class WineItems extends Component {
  state = {
    selectedTag: null,
  };

  clickHandler(item) {
    this.props.selected(item);

    this.setState({
      selectedTag: item.tag,
    });
  }

  render() {
    const appLayout = localStorage.getItem("app_layout") || "ementas_digitais";
    const appLayoutConfig = require("../../assets/layout-config.json");
    const currentLayoutConfig = appLayoutConfig[appLayout];

    let attachedClasses = [classes.WineItems, classes.Close].join(" ");

    if (this.props.show) {
      attachedClasses = [classes.WineItems, classes.Open].join(" ");
    }

    if (currentLayoutConfig.showMapOnGrid) {
      return null;
    } else {
      return (
        <div className={classes[appLayout]}>
          <div className={attachedClasses}>
            <WineMap selected={this.props.selected} close={this.props.close} />
            {currentLayoutConfig.externalWineFilter ? (
              window.innerHeight > window.innerWidth ? (
                <div className={classes.WineFilters}>
                  {this.props.items.map((item) => (
                    <WineItem
                      onClick={() => this.clickHandler(item)}
                      isSelected={item.tag === this.state.selectedTag}
                      key={item.id}
                      name={item.name}
                      tag={item.tag}
                    />
                  ))}
                </div>
              ) : null
            ) : (
              this.props.items.map((item) => (
                <WineItem
                  onClick={() => this.props.selected(item)}
                  key={item.id}
                  name={item.name}
                  tag={item.tag}
                />
              ))
            )}
          </div>
        </div>
      );
    }
  }
}

export default WineItems;
