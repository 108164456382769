import React, { Component } from "react";
import axiosMenuscreen from "../../../axios-menuscreen";
import axios from "axios";

import Modal from "../../UI/Modal/Modal";
import FoodItemDetails from "./FoodItemDetails/FoodItemDetails";
import FoodItemDetailsChefHouse from "./FoodItemDetails/FoodItemDetailsChefHouse";

import classes from "./FoodItem.module.scss";

class FoodItem extends Component {
  state = {
    detailed: false,
    imageUrl: require("../../../assets/images/no_image.png"),
  };

  detailViewOpenedHandler = () => {
    document.body.style.overflow = "hidden";

    this.setState({
      detailed: true,
    });
  };

  detailViewClosedHandler = () => {
    document.body.style.overflow = "auto";
    this.setState({
      detailed: false,
    });
  };

  componentDidMount() {
    const lang = localStorage.getItem("lang") || "pt";
    const appLayout = localStorage.getItem("app_layout") || "ementas_digitais";
    const appLayoutConfig = require("../../../assets/layout-config.json");
    const currentLayoutConfig = appLayoutConfig[appLayout];
    const imageBasePath = `https://plataforma.ementasdigitais.pt/media/cache/${currentLayoutConfig.itemImageResolution}/uploads/images/`;

    if (this.props.image) {
      if (
        this.imageExists(
          imageBasePath,
          this.props.image,
          lang,
          currentLayoutConfig.itemImageResolution
        )
      ) {
        this.setState((prevState) => ({
          ...prevState,
          imageUrl: imageBasePath + this.props.image,
        }));
      }
    }
  }

  imageExists = async (url, image, lang, resolution) => {
    let exists = false;

    await axios
      .head(url + image)
      .then(() => (exists = true))
      .catch(() => {
        this.generateImageCache(image, lang, resolution)
          .then(() => this.imageExists(url, image).bind(this))
          .catch(() => (exists = false));
      });

    return exists;
  };

  generateImageCache = async (image, lang, resolution) => {
    await axiosMenuscreen
      .get(
        `https://plataforma.ementasdigitais.pt/api/${lang}/image/${resolution}/${image}/path`
      )
      .then((res) => {
        this.setState((prevState) => ({
          ...prevState,
          imageUrl: `${res.data.path}?${Math.random()}`,
        }));
      })
      .catch(() => {
        this.setState((prevState) => ({
          ...prevState,
          imageUrl: require("../../../assets/images/no_image.png"),
        }));
      });
  };

  render() {
    const lang = localStorage.getItem("lang") || "pt";
    const translations = require(`../../../assets/translations/${lang}.json`);
    const appLayout = localStorage.getItem("app_layout") || "ementas_digitais";
    const appLayoutConfig = require("../../../assets/layout-config.json");
    const currentLayoutConfig = appLayoutConfig[appLayout];

    const wineTypeTags = [
      "rose",
      "red_wine",
      "white_wine",
      "green_wine",
      "espumante",
      "licoroso",
    ];
    const wineRegionTags = [
      "all_regions",
      "minho",
      "transmontano_douro",
      "beiras",
      "lisboa",
      "tejo",
      "alentejo",
      "algarve",
      "setubal",
      "madeira",
      "acores",
      "mundo",
    ];
    const sakeTypeTags = ["jummai", "ginjo", "daiginjo", "honjozo"];
    const drinkTags = [
      "water",
      "beer",
      "wines",
      ...wineTypeTags,
      ...wineRegionTags,
      ...sakeTypeTags,
    ];
    const categoryClass =
      drinkTags.indexOf(this.props.category) >= 0
        ? "drinks"
        : this.props.category;

    let foodItemClasses = [classes.FoodItem];

    if (
      [...wineTypeTags, ...wineRegionTags].indexOf(this.props.category) >= 0
    ) {
      if (this.props.filter.wineType) {
        if (this.props.categories.indexOf(this.props.filter.wineType) < 0) {
          foodItemClasses.push(classes.hide);
        }
      }

      if (this.props.filter.wineRegion) {
        if (this.props.categories.indexOf(this.props.filter.wineRegion) < 0) {
          foodItemClasses.push(classes.hide);
        }
      }
    }

    if (sakeTypeTags.indexOf(this.props.category) >= 0) {
      if (this.props.filter.sakeType) {
        if (this.props.categories.indexOf(this.props.filter.sakeType) < 0) {
          foodItemClasses.push(classes.hide);
        }
      }
    }

    const foodItemNameClasses = [
      classes.FoodItemName,
      classes[categoryClass] && currentLayoutConfig.colorFoodItems
        ? classes[categoryClass]
        : classes.default,
    ].join(" ");

    const modal = this.state.detailed ? (
      <Modal
        show={this.state.detailed}
        modalClosed={this.detailViewClosedHandler}
      >
        {appLayout === "chef_house" || currentLayoutConfig.vertical_layout ? (
          <FoodItemDetailsChefHouse
            image={this.state.imageUrl}
            id={this.props.id}
            name={this.props.name}
            description={this.props.description}
            nutrition={this.props.nutrition}
            allergies={this.props.allergies}
            price={this.props.price}
            otherPrices={this.props.otherPrices}
            close={this.detailViewClosedHandler}
          />
        ) : (
          <FoodItemDetails
            image={this.state.imageUrl}
            id={this.props.id}
            name={this.props.name}
            description={this.props.description}
            nutrition={this.props.nutrition}
            allergies={this.props.allergies}
            price={this.props.price}
            otherPrices={this.props.otherPrices}
            close={this.detailViewClosedHandler}
          />
        )}
      </Modal>
    ) : null;

    let prices = "";

    if (this.props.price) {
      prices += `€ ${parseFloat(this.props.price).toFixed(2)}`;
    }

    if (Object.keys(this.props.otherPrices).length > 0) {
      if (prices.length > 0) {
        prices += " / ";
      }

      Object.keys(this.props.otherPrices).forEach((objKey, index) => {
        const otherPrice = this.props.otherPrices[objKey].toString().trim();

        if (parseFloat(otherPrice) > 0) {
          prices += `€ ${parseFloat(otherPrice).toFixed(2)} `;
        }

        if (index < Object.keys(this.props.otherPrices).length - 1) {
          prices += " / ";
        }
      });
    }

    return (
      <div className={classes[appLayout]}>
        {modal}
        <div
          onClick={this.detailViewOpenedHandler}
          className={foodItemClasses.join(" ")}
        >
          <div className={classes.FoodItemImageWrapper}>
            <div
              className={classes.FoodItemImage}
              style={{ backgroundImage: `url(${this.state.imageUrl}` }}
            />
          </div>
          {currentLayoutConfig.extraDetailsFoodItems ? (
            <div className={foodItemNameClasses}>
              <span className={classes.itemName}>{this.props.name}</span>
              <span className={classes.itemPrice}>{prices}</span>
              <span className={classes.itemMore}>
                {translations.menu.buttons.more}
              </span>
            </div>
          ) : (
            <div className={foodItemNameClasses}>
              <p>{this.props.name}</p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default FoodItem;
