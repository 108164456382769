import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";
import { loadState, saveState } from "./localstorage";
import { thunk } from "redux-thunk";
import throttle from "lodash/throttle";
import rootReducer from "./reducers";

const composeEnhancers = composeWithDevTools({
  // Specify name here, actionsBlacklist, actionsCreators and other options if needed
});

const persistedState = loadState();

const store = createStore(
  rootReducer,
  persistedState,
  composeEnhancers(applyMiddleware(thunk))
);

store.subscribe(
  throttle(() => {
    saveState({
      orderItems: store.getState().orderItems,
    });
  }, 1000)
);

export default store;
