import React from "react";

import classes from "./DrinkItem.module.scss";
import icon from "../../../assets/images/icons/drinks.png";

const DrinkItem = (props) => {
  const appLayout = localStorage.getItem("app_layout") || "ementas_digitais";
  const appLayoutConfig = require("../../../assets/layout-config.json");
  const currentLayoutConfig = appLayoutConfig[appLayout];

  return (
    <div className={classes[appLayout]}>
      <button className={classes.DrinkItem} onClick={props.onClick}>
        {currentLayoutConfig.menuIcons ? <img alt="drinks" src={icon} /> : null}
        <span
          className={[
            classes.title,
            props.isSelected ? classes.selected : null,
          ].join(" ")}
        >
          <span>
            {props.name}{" "}
            {currentLayoutConfig.childrenArrowsNavigation &&
            ((props.isWineItem && appLayout !== "chef_house_vertical") ||
              props.isSakeItem) ? (
              <span className={classes.arrow}>{"\uf178"}</span>
            ) : (
              ""
            )}
          </span>
        </span>
      </button>
    </div>
  );
};

export default DrinkItem;
