import React, { Component } from "react";
import axios from "../../axios-menuscreen";

import WelcomeBanner from "../UI/WelcomeBanner/WelcomeBanner";
import LanguageItems from "../LanguageItems/LanguageItems";
import NavigationItems from "../NavigationItems/NavigationItems";

import classes from "./SideDrawer.module.scss";

class SideDrawer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDisclaimer: false,
      languages: [],
      sections: [],
      showBanner: false,
    };

    this.showOrderItems = this.showOrderItems.bind(this);
    this.showBannerCloseHandler = this.showBannerCloseHandler.bind(this);
    this.toBoolean = this.toBoolean.bind(this);
  }

  loadMenuInfo() {
    const baseURL = "https://plataforma.ementasdigitais.pt/uploads/images/";

    axios
      .get()
      .then((response) => {
        this.setState({
          image: response.data.image
            ? baseURL + response.data.image
            : undefined,
          languages: response.data.languages,
          sections: response.data.sections,
          welcomeText: response.data.welcomeText
            ? baseURL + response.data.welcomeText
            : "",
          welcomeBanner: response.data.welcomeBanner
            ? baseURL + response.data.welcomeBanner
            : undefined,
        });

        localStorage.setItem(
          "image",
          response.data.image ? baseURL + response.data.image : undefined
        );
        localStorage.setItem(
          "welcomeText",
          response.data.welcomeText ? response.data.welcomeText : ""
        );
        localStorage.setItem(
          "welcomeBanner",
          response.data.welcomeBanner
            ? baseURL + response.data.welcomeBanner
            : undefined
        );
        localStorage.setItem(
          "languages",
          JSON.stringify(response.data.languages)
        );
        localStorage.setItem(
          "sections",
          JSON.stringify(response.data.sections)
        );
        localStorage.setItem(
          "display_cart",
          response.data.parameters && response.data.parameters.display_cart
        );
        localStorage.setItem(
          "display_lang_side_sections",
          response.data.parameters &&
            response.data.parameters.display_lang_side_sections
        );

        if (
          this.state.welcomeBanner &&
          this.state.welcomeBanner !== "undefined"
        ) {
          this.setState({
            showBanner: true,
          });
        }
      })
      .catch((error) => {
        // Code 602 => Suspended
        if (
          error.response &&
          error.response.data &&
          error.response.data.code &&
          error.response.data.code === 602
        ) {
          localStorage.clear();
          window.open(window.location + "suspended", "_self", "");
        } else {
          console.warn("Serving from Cache");
          this.setState({
            image: localStorage.getItem("image"),
            welcomeText: localStorage.getItem("welcomeText"),
            welcomeBanner: localStorage.getItem("welcomeBanner"),
            languages: JSON.parse(localStorage.getItem("languages")),
            sections: JSON.parse(localStorage.getItem("sections")),
          });
        }

        if (
          this.state.welcomeBanner &&
          this.state.welcomeBanner !== "undefined"
        ) {
          this.setState({
            showBanner: true,
          });
        }
      });
  }

  showBannerCloseHandler = () => {
    this.setState({
      showBanner: false,
    });
  };

  componentDidMount() {
    this.setState({
      image: localStorage.getItem("image"),
      welcomeText: localStorage.getItem("welcomeText"),
      welcomeBanner: localStorage.getItem("welcomeBanner"),
      languages: JSON.parse(localStorage.getItem("languages")),
      sections: JSON.parse(localStorage.getItem("sections")),
    });

    this.loadMenuInfo();
  }

  showLandingPage() {
    localStorage.removeItem("lang");
    window.location.reload();
  }

  showOrderItems() {
    this.props.selected("order");
    this.props.setVisible("order");
  }

  toBoolean(value) {
    return value === "true";
  }

  render() {
    const lang = localStorage.getItem("lang") || "pt";
    const translations = require(`../../assets/translations/${lang}.json`);
    const appLayout = localStorage.getItem("app_layout") || "ementas_digitais";
    const appLayoutConfig = require("../../assets/layout-config.json");
    const currentLayoutConfig = appLayoutConfig[appLayout];
    const hasOrderOptions = this.toBoolean(
      localStorage.getItem("display_cart")
    );
    const hasLanguageItems = this.state.languages?.length > 0;

    const logo = this.state.image ? (
      <div className={classes.Logo}>
        <div className={classes.imageWrapper}>
          <img
            src={this.state.image}
            onClick={
              currentLayoutConfig.landingPage ? this.showLandingPage : null
            }
            alt="logo"
          />
        </div>
        <div className={classes.imageBottomDecorator}>
          <div className={classes.decorator}></div>
        </div>
      </div>
    ) : null;

    const banner =
      this.state.welcomeBanner && this.state.welcomeBanner !== "undefined" ? (
        <WelcomeBanner
          show={this.state.showBanner}
          bannerClosed={this.showBannerCloseHandler}
        >
          <div
            className={classes.welcomeBanner}
            style={{ backgroundImage: "url(" + this.state.welcomeBanner + ")" }}
          ></div>
        </WelcomeBanner>
      ) : null;

    return (
      <div className={classes[appLayout]}>
        {banner}
        <div className={classes.SideDrawer}>
          <div className={classes.wrapper}>
            {logo}
            {hasLanguageItems ? (
              <LanguageItems items={this.state.languages} />
            ) : null}
            <NavigationItems
              setVisible={this.props.setVisible}
              visible={this.props.visibleItems}
              selected={this.props.selected}
              items={this.state.sections}
            />
            {currentLayoutConfig.hiddenDisclaimer ? (
              <div
                className={[
                  classes.Disclaimer,
                  this.state.showDisclaimer ? classes.selected : null,
                ].join(" ")}
              >
                <div
                  className={classes.Info}
                  onClick={() =>
                    this.setState({
                      showDisclaimer: !this.state.showDisclaimer,
                    })
                  }
                ></div>
                {hasOrderOptions ? (
                  <div
                    className={classes.viewOrder}
                    onClick={this.showOrderItems}
                  >
                    <span className={classes.viewOrderText}>
                      {translations.menu.order.view}
                    </span>
                    <div className={classes.viewOrderIcon}></div>
                  </div>
                ) : null}
                {this.state.showDisclaimer ? (
                  <span className={classes.disclaimerText}>
                    {translations.menu.disclaimers.general}
                  </span>
                ) : null}
              </div>
            ) : (
              <p>{translations.menu.disclaimers.general}</p>
            )}
          </div>
        </div>
        <div
          className={classes.welcomeText}
          dangerouslySetInnerHTML={{ __html: this.state.welcomeText }}
        />
      </div>
    );
  }
}

export default SideDrawer;
